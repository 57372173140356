import React, { useMemo } from 'react';
import ButtonLink from 'src/components/button/ButtonLink';
import { useStaticQuery, graphql } from 'gatsby';
import { v4 as uuid } from 'uuid';
import './Prices.scss';

const pricesQuery = graphql`
  query {
    allPricesJson {
      edges {
        node {
          kidsPrice
          studentPrice
          adultPrice
          name
          id
          order
        }
      }
    }
  }
`;

export default () => {
  const {
    allPricesJson,
  } = useStaticQuery(pricesQuery);

  const priceItems = useMemo(() => {
    return allPricesJson.edges.map(({ node }) => ({ ...node })).sort((a, b) => a.order - b.order);
  }, [allPricesJson]);

  return (
    <div className="prices-container">
      <div className="prices-collection">
        {
          priceItems.map(({
            id: priceRowId,
            name,
            adultPrice,
            kidsPrice,
            studentPrice,
          }) => (
            <div key={priceRowId} className="price-box">
              <h2 className="row-title">{name}</h2>
              <div className="price-container">
                {
                  adultPrice ? (
                    <div key={uuid()} className="price">
                      <h4>Adult</h4>
                      <h3 key={uuid()}>€{adultPrice}</h3>
                    </div>
                  ) : ''
                }
                {
                  studentPrice ? (
                    <div key={uuid()} className="price">
                      <h4>Student</h4>
                      <h3 key={uuid()}>€{studentPrice}</h3>
                    </div>
                  ) : ''
                }
                {
                  kidsPrice ? (
                    <div key={uuid()} className="price">
                      <h4>Kids</h4>
                      <h3 key={uuid()}>€{kidsPrice}</h3>
                    </div>
                  ) : ''
                }
              </div>
              <ButtonLink href="https://www.jitshare.com/academylist?academy_id=33" className="call-to-action">Register</ButtonLink>
            </div>
          ))
        }
      </div>
    </div>
  );
};
