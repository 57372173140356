export const getHourDifference = (a, b) => {
  const aHours = extractHoursFromText(a);
  const bHours = extractHoursFromText(b);

  return bHours - aHours;
};

export const extractHoursFromText = (text) => {
  const [hours, minutes] = text.split(':');

  return parseInt(hours, 10) + (minutes / 60.0);
};
