import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'src/components/UI/Container';
import Prices from 'src/components/prices/Prices';
import Timetable from 'src/components/timetable/Timetable';
import { Helmet } from "react-helmet"
import ButtonLink from 'src/components/button/ButtonLink';
import Header from 'src/components/header/Header';
import Footer from 'src/components/footer/Footer';
import './programs.scss';

const textQuery = graphql`
  query {
    programs: allWebsiteTextsJson(filter: {identifier: {eq: "programs"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
    freeLessons: allWebsiteTextsJson(filter: {identifier: {eq: "free-lessons"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
  }
`;

export default () => {
  const {
    freeLessons: {
      nodes: [
        freeLessonsText,
      ],
    },
    programs: {
      nodes: [
        programsText,
      ],
    },
  } = useStaticQuery(textQuery);

  return (
    <div className="page programs">
      <Header />
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;900&display=swap" rel="stylesheet" />
        <title>BJJ Gent | Programs</title>
      </Helmet>
      {
        programsText.shown ? (
          <Container className="dark info-container offset-from-top">
            <h2 className="uppercase">{programsText.title}</h2>
            {
              programsText.text.split('\n').map((paragraph) => (
                <p key={paragraph}>
                  {paragraph}
                </p>
              ))
            }
            <ButtonLink href="https://www.jitshare.com/academylist?academy_id=33" className="register-button">Register here</ButtonLink>
          </Container>
        ) : ''
      }
      {
        freeLessonsText.shown ? (
          <Container className={`free-lessons-container ${programsText.shown ? '' : 'offset-from-top'}`}>
            <h2 className="uppercase"><a className="anchor" id="free-lesson"></a>{freeLessonsText.title}</h2>
            {
              freeLessonsText.text.split('\n').map((paragraph) => (
                <p key={paragraph}>
                  {paragraph}
                </p>
              ))
            }
          </Container>
        ) : ''
      }
      <Container>
        <h2 className="uppercase">Our Schedule</h2>

        <Timetable />
      </Container>

      <Container>
        <h2 className="uppercase">Our Prices</h2>

        <Prices />
      </Container>
      <Footer />
    </div >
  );
}
